import React, {useContext, useEffect} from "react";
import {navigate} from "gatsby";
import {PageLayout, LoadingSpinner} from "../components/components";
import firebaseApp from "../services/firebase-app";
import {AppAlertsContext} from "../context/app-alerts-context";
import appContent from "../markdown/app-content";
import {AlertType} from "../components/providers/app-alerts/AppAlerts";

//
// This page will redirect to the home page after logging out the user
//
const RedirectToHomePageAfterLogoutPage = () => {
  const {popError, popAlert} = useContext(AppAlertsContext);
  useEffect(() => {
    (async () => {
      try {
        await navigate("/");
        await firebaseApp.auth().signOut();
        popAlert(appContent.alerts.loggedOutSuccess, AlertType.SUCCESS);
      } catch(e) {
        popError(e.message);
      }
    })();
  }, []);

  return (
    <PageLayout>
      <LoadingSpinner isLoading={true}/>
    </PageLayout>
  );
};

export default RedirectToHomePageAfterLogoutPage;
